/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-minus-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M12 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V2a2 2 0 00-2-2M6 7.5h4a.5.5 0 010 1H6a.5.5 0 010-1"/>',
    },
});
